export default build =>
    build.mutation({
        query(payload) {         
            return {
                url: '/testimonial/addTestimonial',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allTestimonials']       
    })
