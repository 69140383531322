export default build =>
    build.query({
        tagTypes: ['allYoutubeVideos'],
        keepUnusedDataFor: 1,
        query(payload) {
            console.log("in get event year",payload)            
            return {
                url: `/photogallery/getAllYoutubeVideos?page_number=${payload.page_number}&page_size=${payload.page_size}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['allYoutubeVideos']
    })
