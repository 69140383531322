import { api } from '../../api'

import addYoutubeVideo from './addYoutubeVideo'
import deleteYoutubeVideo from './deleteYoutubeVideo'
import getAllYoutubeVideos from './getAllYoutubeVideos'


export const videoGalleryApi = api.injectEndpoints({
    endpoints: build => ({       
        AddYoutubeVideo:addYoutubeVideo(build), 
        DeleteYoutubeVideo:deleteYoutubeVideo(build),  
        GetAllYoutubeVideos:getAllYoutubeVideos(build)          

    }),
    overrideExisting: true,
})

export const { 
 useAddYoutubeVideoMutation,
 useDeleteYoutubeVideoMutation,
 useGetAllYoutubeVideosQuery

 } = videoGalleryApi

