import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { useAddProductMutation, useDeleteProductMutation, useGetAllProductsQuery, useUpdateProductMutation } from '../Services/modules/products';
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import useDataTable from '../hooks/useDataTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateProductData } from '../Store/ProductData';
import WarningModal from '../Components/WarningModal';

function Products() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {   
    handleFirstPage ,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex  ,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
    // Add other state variables and functions here
  }=useDataTable()
    const [allProducts, setProducts] = useState([]);    
    const [isSumbmiting,setIsSubmitting]=useState(false)
    const [isUpdate,setIsUpdate]=useState("")  
    const[isDelete,setIsDelete]=useState(false) 
    const[isDeleteModal,setDeleteModal]=useState(false)
    
    const [AddProduct, { data, isSuccess, isLoading, isError, error }] = useAddProductMutation();

    const{data:dataProduct=[],
      isSuccess:isSuccessProduct,
      isLoading:isLoadingProduct,
      error:errorProduct,
      refetch
    }=useGetAllProductsQuery({page_number:page,page_size:rowsPerPage})

    // const{data:dataProduct=[],
    //   isSuccess:isSuccessProduct,
    //   isLoading:isLoadingProduct,
    //   error:errorProduct,
    //   refetch
    // }=useGetAllProductsQuery()

   

    const [product_data, setProductData] = useState({
        product_id:"",
        product_name: "",
        product_introduction:"",
        product_benefits:"",
        packing_details:"",
        recommended_crops:"",
        recommended_dose:""     ,
        product_link:""  ,
        recommended_dripping:"",
        disclaimer:"",
        caution:""
      });
    
     
      const [UpdateProduct, { 
        data:updateData=[], 
        isSuccess:updateIsSuccess, 
        isLoading:updateIsLoading, 
        isError:updateIsError, 
        error:updateError }] = useUpdateProductMutation();
       
        const [DeleteProduct, { 
          data:deleteData=[], 
          isSuccess:deleteIsSuccess, 
          isLoading:deleteIsLoading, 
          isError:deleteIsError, 
          error:deleteError }] = useDeleteProductMutation();
      function handleChange(name, value) {
        setProductData({
          ...product_data,
          [name]: value
        });
      }
      const handleQuillChange = (content, delta, source, editor) => {
       // const plainText = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
        handleChange("product_benefits", content);
      };
      const clearProductData=()=>{
        console.log("In clear product data")
      
        setProductData({
          ...product_data,
          product_id:"",      
          product_name:""   ,
        product_introduction:"",

          product_benefits:"",
          packing_details:"",
          recommended_crops:"",
          recommended_dose:""   ,
          is_homepage:false,
          product_link:"",
          recommended_dripping:"",
          disclaimer:"",
          caution:""

        });

      }
      const onSubmit=()=>{
        setIsSubmitting(true)
        if(!isUpdate)
        {
          AddProduct(product_data)

        }
        if(isUpdate)
        {
          UpdateProduct(product_data)

        }
      }
      const handleProductEdit=(dataRow)=>{
        console.log("dataRow",dataRow)
        setProductData({
        ...product_data,
        product_id:dataRow._id,
        product_name: dataRow.product_name,
        product_introduction:dataRow.product_introduction,

        product_benefits:dataRow.product_benefits,
        packing_details:dataRow.packing_details,
        recommended_crops:dataRow.recommended_crops,
        recommended_dose:dataRow.recommended_dose ,
        is_homepage:dataRow.is_homepage,
        product_link:dataRow.product_link,
        recommended_dripping:dataRow.recommended_dripping,
        disclaimer:dataRow.disclaimer,
        caution:dataRow.caution
      });
      setIsUpdate(true)
      
      }
      const handleProductDelete=(dataRow)=>{
       console.log("In handle product delete dataRow",dataRow)
        setDeleteModal(true)
        setProductData({
          ...product_data,
          product_id:dataRow._id,         
        });

      }
  
 
  useEffect(() => {
    refetch();
  }, [page]);
  useEffect(() => {

   if(isSuccess)
   {
    setIsSubmitting(false)

    toast.success(data.message)
    clearProductData()
   // refetch()
   }
  }, [data]);

  useEffect(()=>{
console.log("In get product useeffet")
if(isSuccessProduct)
{
  console.log("dataProduct ddddd",dataProduct)
  setProducts(dataProduct.data)
  setTotalRows(dataProduct.totalRows)
}
  },[dataProduct])

  useEffect(()=>{

    if(updateIsSuccess)
    {
      console.log("updateddataProduct",updateData)

      if(!updateData.error)
      {
        // setProducts(updateData.data)
        // setTotalRows(updateData.totalRows)
        toast.success(updateData.message)
        setIsUpdate(false)
        clearProductData()
        setIsSubmitting(false)

      }
      else
      {
        toast.error(updateData.message)
    clearProductData()


      }
    
    }
      },[updateData])
      useEffect(()=>{

        if(deleteIsSuccess)
        {
          console.log("deleteddataProduct",deleteData)
    
          if(!deleteData.error)
          {
            // setProducts(deleteData.data)
            // setTotalRows(deleteData.totalRows)
            toast.success(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
    clearProductData()

    //refetch()
            
          }
          else
          {
            toast.error(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
    
          }
        
        }
          },[deleteData])
      useEffect(() => {
        if(isDelete)
        {
          DeleteProduct(product_data)
        }
      
      }, [isDelete]);
      const navigateToProductDetails=(productDetails)=>{
        dispatch(updateProductData({data:productDetails}))
        navigate({
          pathname: '/productdetails',
          // search: `?id=${courseDetails._id}`,    
        });
  
       }
     
       
    const productScreen=()=>{
        return(
            <>
            <ToastContainer/>
            
              <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
            
        <main id="main" class="main">

            <div className='container'>
              <div className='row'>
              <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add Product Details</h5>
             
                <div className="form-group">
                  <label htmlFor="input1">Product Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="product_name"
                  value={product_data.product_name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input3">Product Introduction</label>
                  <textarea 
    className="form-control mb-3" 
    id="input1"
    name="product_introduction"
    value={product_data.product_introduction}
    onChange={(e) => handleChange(e.target.name, e.target.value)}
    rows="5" // Adjust the number of rows as needed
/>
                  {/* <ReactQuill
    className="mb-3"
    value={product_data.product_benefits}
    onChange={handleQuillChange}
  
  />                */}
                </div>  
                <div className="form-group">
                  <label htmlFor="input3">Product Benifits</label>
                  <textarea 
    className="form-control mb-3" 
    id="input1"
    name="product_benefits"
    value={product_data.product_benefits}
    onChange={(e) => handleChange(e.target.name, e.target.value)}
    rows="5" // Adjust the number of rows as needed
/>
                  {/* <ReactQuill
    className="mb-3"
    value={product_data.product_benefits}
    onChange={handleQuillChange}
  
  />                */}
                </div>    
                <div className="form-group">
                  <label htmlFor="input2">Packing Details</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="packing_details"
                  value={product_data.packing_details}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Dosage</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="recommended_dose"
                  value={product_data.recommended_dose}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Dripping</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="recommended_dripping"
                  value={product_data.recommended_dripping}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Disclaimer</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="disclaimer"
                  value={product_data.disclaimer}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Caution</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="caution"
                  value={product_data.caution}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Crops</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="recommended_crops"
                  value={product_data.recommended_crops}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Product link</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="product_link"
                  value={product_data.product_link}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}

                   />
                </div>
                <div className='form-group'>
                      <label>
                        <input
                  className="mb-3" 
                          name="is_homepage"
                          type='checkbox'
                          checked={product_data.is_homepage}
                          onChange={(e) => handleChange(e.target.name, e.target.checked)}
                        />{' '}
                        Show Product On Homepage
                      </label>
                    </div>
               
                      
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>
             
            </div>
          </div>
        </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body'>
                        <table className="bordered-table">
        <thead>
          <tr>
          <th>Action</th>
            <th>Index</th>
            <th>Product Name</th>
            {/* Add more column headers as needed */}
          </tr>
        </thead>
        <tbody>
          {allProducts.map((item, index) => (
            <tr key={index}>
              <td>
                <MdEdit onClick={()=>handleProductEdit(item)} 
                style={{cursor:'pointer'}}/> 
              <MdDelete onClick={()=>handleProductDelete(item)} style={{cursor:'pointer'}}/>
              </td>
              <td>{sequenceStartIndex + index}</td>
              <td>
              <a onClick={()=>navigateToProductDetails(item)} style={{cursor:'pointer',color:'blue'}}>

                {item.product_name}
                </a>
                </td>             
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button>
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
      <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button>
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div>
     
                        </div>
                    </div>
                </div>
              </div>
              
             
            </div>
            </main>
            </>
        )
     
    }
  return (
    <Template>{productScreen()}</Template>
  )
}

export default Products