import { api } from '../../api'
import addTestimonial from './addTestimonial'
import deleteTestimonial from './deleteTestimonial'
import updateTestimonial from './updateTestimonial'
import getAllTestimonials from './getAllTestimonials'


export const testimonialApi = api.injectEndpoints({
    endpoints: build => ({
        AddTestimonial:     addTestimonial(build),
        DeleteTestimonial: deleteTestimonial(build) ,
        UpdateTestimonial:updateTestimonial(build),
        GetAllTestimonial:getAllTestimonials(build)
       
    }),
    overrideExisting: true,
})

export const { 
    useAddTestimonialMutation,
    useUpdateTestimonialMutation,
    useDeleteTestimonialMutation,
    useGetAllTestimonialQuery,
  
 } = testimonialApi

