export default build =>
    build.mutation({
        query(payload) {         
            return {
                url: '/photogallery/addYoutubeVideo',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allYoutubeVideos'],
       
    })
