import React,{useEffect} from 'react'

import { 
 
    Route,
    Routes,     
    Navigate,
    Outlet,
  
   } from 'react-router-dom'

import Home from './Pages/Home'
import Login from './Pages/Login';
import { useSelector } from 'react-redux';
import Signup from './Pages/Signup';
import Products from './Pages/Products';
import ProductDetails from './Pages/ProductDetails';
import PhotoGalleryEvent from './Pages/PhotoGalleryEvent';
import PhotoGallery from './Pages/PhotoGallery';
import PhotoGalleryImages from './Pages/PhotoGalleryImages';
import Testimonials from './Pages/Testimonials';
import TestimonialDetails from './Pages/TestimonialDetails';
import VideoGallery from './Pages/VideoGallery';

// import Courses from './Pages/Courses';
// import CourseContent from './Pages/CourseContent';
// import Reports from './Pages/Reports';

function AppRoutes() {
   const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  
    const ProtectedRoute = ({
        isAllowed,
        redirectPath = '/',
        children,
      }) => {
        if (!isAllowed) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };
  return (
    <>
 
   <Routes>
   <Route index element={<Login/>}/>  
   <Route path="/signup" element={<Signup/>}/>    
   <Route path="/login" element={<Login/>}/>    

   <Route element={<ProtectedRoute isAllowed={isLoggedin} />}>
   <Route path="/dashboard" element={<Home/>}/>    
   <Route path="/products" element={<Products/>}/>    
   <Route path="/productdetails" element={<ProductDetails/>}/>    

   <Route path="/photogallery" element={<PhotoGallery/>}/>    
   <Route path="/photogalleryevent" element={<PhotoGalleryEvent/>}/>    
   <Route path="/photogalleryimages" element={<PhotoGalleryImages/>}/>    
   <Route path="/testimonial" element={<Testimonials/>}/>    
   <Route path="/testimonialdetails" element={<TestimonialDetails/>}/>    

   <Route path="/videogallery" element={<VideoGallery/>}/>    

   {/* <Route path="/courses" element={<Courses/>}/>       
   <Route path="/coursecontent" element={<CourseContent/>}/>   
   <Route path="/reports" element={<Reports/>}/>    */}

   {/* <Route path="dashboard" element={<Reports/>}/>     */}


        </Route>
    </Routes>
  
  
    </>
  )
}

export default AppRoutes