import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { useAddProductMutation, useDeleteProductMutation, useGetAllProductsQuery, useUpdateProductMutation } from '../Services/modules/products';
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import useDataTable from '../hooks/useDataTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateProductData } from '../Store/ProductData';
import WarningModal from '../Components/WarningModal';
import { 
  
    useAddTestimonialMutation, 
    useDeleteTestimonial, 
    useDeleteTestimonialMutation, 
    useGetAllTestimonial, 
    useGetAllTestimonialQuery, 
    useUpdateTestimonial, 
    useUpdateTestimonialMutation} from '../Services/modules/testimonial';
import { updateTestimonialData } from '../Store/TestimonialData';

function Testimonials() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {   
    handleFirstPage ,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex  ,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
    // Add other state variables and functions here
  }=useDataTable()
    const [allTestimonials, setTestimonials] = useState([]);    
    const [isSumbmiting,setIsSubmitting]=useState(false)
    const [isUpdate,setIsUpdate]=useState("")  
    const[isDelete,setIsDelete]=useState(false) 
    const[isDeleteModal,setDeleteModal]=useState(false)
    
    const [AddTestimonial, { 
        data, 
        isSuccess, 
        isLoading, 
        isError, 
        error }] = useAddTestimonialMutation();

    const{data:dataTestimonial=[],
      isSuccess:isSuccessTestimonial,
      isLoading:isLoadingTestimonial,
      error:errorTestimonial,
      refetch
    }=useGetAllTestimonialQuery()
  
    const [testimonial_data, setTestimonialData] = useState({
        testimonial_id:"",
        customer_name: "",
        customer_feedback:"",       
      });
    
     
      const [UpdateTestimonial, { 
        data:updateData=[], 
        isSuccess:updateIsSuccess, 
        isLoading:updateIsLoading, 
        isError:updateIsError, 
        error:updateError }] = useUpdateTestimonialMutation();
       
        const [DeleteTestimonial, { 
          data:deleteData=[], 
          isSuccess:deleteIsSuccess, 
          isLoading:deleteIsLoading, 
          isError:deleteIsError, 
          error:deleteError }] = useDeleteTestimonialMutation();
      function handleChange(name, value) {
        setTestimonialData({
          ...testimonial_data,
          [name]: value
        });
      }
     
      const clearTestimonialData=()=>{
        console.log("In clear product data")
      
        setTestimonialData({
          ...testimonial_data,
          testimonial_id:"",
        customer_name: "",
        customer_feedback:"",       

        });

      }
      const onSubmit=()=>{
        setIsSubmitting(true)
        if(!isUpdate)
        {
          AddTestimonial(testimonial_data)

        }
        if(isUpdate)
        {
          UpdateTestimonial(testimonial_data)

        }
      }
      const handleTestimonialEdit=(dataRow)=>{
        console.log("dataRow",dataRow)
        setTestimonialData({
        ...testimonial_data,
        testimonial_id:dataRow._id,
        customer_name: dataRow.customer_name,
        customer_feedback:dataRow.customer_feedback,
      });
      setIsUpdate(true)
      
      }
      const handleTestimonialDelete=(dataRow)=>{
       console.log("In handle product delete dataRow",dataRow)
        setDeleteModal(true)
        setTestimonialData({
          ...testimonial_data,
          testimonial_id:dataRow._id,         
        });

      }
  
 
  useEffect(() => {
    refetch();
  }, [page]);
  useEffect(() => {
   if(isSuccess)
   {
    setIsSubmitting(false)
    toast.success(data.message)
    clearTestimonialData()   
   }
  }, [data]);

  useEffect(()=>{
console.log("In get testimonials useeffet")
if(isSuccessTestimonial)
{
  console.log("dataTestimonials ddddd",dataTestimonial)
  setTestimonials(dataTestimonial.data)
  setTotalRows(dataTestimonial.totalRows)
}
  },[dataTestimonial])

  useEffect(()=>{

    if(updateIsSuccess)
    {
      console.log("updateddataProduct",updateData)

      if(!updateData.error)
      {
        // setProducts(updateData.data)
        // setTotalRows(updateData.totalRows)
        toast.success(updateData.message)
        setIsUpdate(false)
        clearTestimonialData()
        setIsSubmitting(false)

      }
      else
      {
        toast.error(updateData.message)
        clearTestimonialData()
      }
    
    }
      },[updateData])
      useEffect(()=>{

        if(deleteIsSuccess)
        {
          console.log("deleteddataProduct",deleteData)
    
          if(!deleteData.error)
          {
          
            toast.success(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
            clearTestimonialData()

    //refetch()
            
          }
          else
          {
            toast.error(deleteData.message)
            setIsDelete(false)
            setDeleteModal(false)
    
          }
        
        }
          },[deleteData])
      useEffect(() => {
        if(isDelete)
        {
          DeleteTestimonial(testimonial_data)
        }
      
      }, [isDelete]);
      const navigateToTestimonialDetails=async(testimonialDetails)=>{
       await dispatch(updateTestimonialData({data:testimonialDetails}))
        navigate({
          pathname: '/testimonialdetails',          
        });
  
       }
     
       
    const productScreen=()=>{
        return(
            <>
            <ToastContainer/>
            
              <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
            
        <main id="main" class="main">

            <div className='container'>
              <div className='row'>
              <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add Testimonial Details</h5>
             
                <div className="form-group">
                  <label htmlFor="input1">Customer Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="customer_name"
                  value={testimonial_data.customer_name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input3">Customer Feedback</label>
                  <textarea 
    className="form-control mb-3" 
    id="input1"
    name="customer_feedback"
    value={testimonial_data.customer_feedback}
    onChange={(e) => handleChange(e.target.name, e.target.value)}
    rows="5" // Adjust the number of rows as needed
/>
                  {/* <ReactQuill
    className="mb-3"
    value={product_data.product_benefits}
    onChange={handleQuillChange}
  
  />                */}
                </div>  
               
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>
             
            </div>
          </div>
        </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body'>
                        <table className="bordered-table">
        <thead>
          <tr>
          <th>Action</th>
            <th>Index</th>
            <th>Customer Name</th>          
          </tr>
        </thead>
        <tbody>
          {allTestimonials.map((item, index) => (
            <tr key={index}>
              <td>
                <MdEdit onClick={()=>handleTestimonialEdit(item)} 
                style={{cursor:'pointer'}}/> 
              <MdDelete onClick={()=>handleTestimonialDelete(item)} style={{cursor:'pointer'}}/>
              </td>
              <td>{sequenceStartIndex + index}</td>
              <td>
              <a onClick={()=>navigateToTestimonialDetails(item)} style={{cursor:'pointer',color:'blue'}}>

                {item.customer_name}
                </a>
                </td>             
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button>
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
      <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button>
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div> */}
     
                        </div>
                    </div>
                </div>
              </div>
              
             
            </div>
            </main>
            </>
        )
     
    }
  return (
    <Template>{productScreen()}</Template>
  )
}

export default Testimonials