import React from 'react'
import Template from '../Components/Template'
import { useSelector } from 'react-redux';

function TestimonialDetails() {
    const testimonial_data=useSelector(state => state.testimonialData.testimonial_data);  


    const testimonialDetailsScreen=()=>{
      return ( 
      <>
         <main id="main" class="main">
         <div className='conatiner'>
            <div className='card'>
            <div className='card-body'>
            <p>
             Feedback: {testimonial_data.customer_feedback}
                                
            </p> 
                </div>
            </div>
        </div>

         </main>
       
        </>)
    }
  return (
    <Template>{testimonialDetailsScreen()}</Template>
  )
}

export default TestimonialDetails