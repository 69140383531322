import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Template from '../Components/Template';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../Config';
import axios from 'axios';
import { useAddProductCompositionMutation, useDeleteProductCompositionMutation, useDeleteProductImageMutation, useGetProductByIdQuery, useMarkThumbnailImageMutation } from '../Services/modules/products';
import WarningModal from '../Components/WarningModal';

function ProductDetails() {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const is_product_data=useSelector(state => state.productData.product_data?true:false);
    const product_data=useSelector(state => state.productData.product_data);  
    const userToken = useSelector(state => state.userData.user_data.token);
    const[isLoadingPage,setIsLoading]=useState(true)
    const[product_details,setProductDetails]=useState()
    const[product_id,setProductId]=useState(product_data._id)
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const[product_images,setProductImages]=useState([])
    const[isDeleteModal,setDeleteModal]=useState(false)
    const[isDeleteModalComposition,setDeleteModalComposition]=useState(false)

    const[isDelete,setIsDelete]=useState(false) 
    const[isDeleteComposition,setIsDeleteComposition]=useState(false) 

const[image_id,setImageId]=useState()
const[composition_id,setCompositionId]=useState()

const [thumbnailIndex, setThumbnailIndex] = useState(-1); 
const[is_thumbnail_image,setIsThumbnailImage]=useState(false)
const[composition_name,setCompositionName]=useState("")
const[composition_quantity,setCompositionQuantity]=useState("")
const[productComposition,setProductComposition]=useState([])
const [isSumbmiting,setIsSubmitting]=useState(false)
const [isUpdate,setIsUpdate]=useState("")  
useEffect(()=>{
  if(is_product_data)
  {
      setIsLoading(false)
      console.log("product_data",product_data)
      setProductDetails(product_data)
      setProductId(product_data._id)
  }
  else
  {
    navigate({
      pathname: '/products',
      // search: `?id=${courseDetails._id}`,    
    });
     // navigate("/products")
  }
},[])
const [AddProductComposition, { 
  data:compositionData, 
  isSuccess:compositionIsSuccess, 
  isLoading:compositionIsLoading, 
  isError:compositionIsError, 
  error:compositionError
 }] = useAddProductCompositionMutation();
useEffect(()=>{
  if(compositionIsSuccess)
    {
      console.log("compositionData",compositionData)
      setCompositionName("")
      setCompositionQuantity("")
      setCompositionId("")
      if(compositionData.error==false)
       {
        toast.success("Composition added successfully")
       }
       else
       {
         return toast.error("Something went wrong")
       }
    }
},[compositionData])
   
    const{data,
      isSuccess,
      isLoading,
      error,
      refetch
    }=useGetProductByIdQuery({product_id:product_id})
    const [DeleteProductImage, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteIsLoading, isError:deleteIsError, error:deleteError }] = useDeleteProductImageMutation();
    const [DeleteProductComposition, { data:deleteDataComposition, isSuccess:deleteSuccessComposition, isLoading:deleteIsLoadingComposition, isError:deleteIsErrorComposition, error:deleteErrorComposition }] = useDeleteProductCompositionMutation();

    const [MarkThumbnailImage, { data:thumbnailImageData, isSuccess:thumbnailImageSuccess, isLoading:thumbnailImageIsLoading, isError:thumbnailImageIsError, error:thumbnailImageError }] = useMarkThumbnailImageMutation();

    useEffect(()=>{
      console.log("product data by id",data)
      if(isSuccess)
      {
        setProductImages(data.data.product_images)
        data.data.product_images.map((info,index)=>{
          console.log("infoooo",info)
          if(info.is_thumbnail_image==true)
          {
            setThumbnailIndex(index)
          }
          
        })
        setProductComposition(data.data?.product_composition)
        //setThumbnailIndex()
      }
    },[data])
    useEffect(() => {
      if(isDelete)
      {
        console.log("In delete useeffect")
        DeleteProductImage({product_id:product_id,image_id:image_id})

      }
    
    }, [isDelete]);
    useEffect(() => {
      if(isDeleteComposition)
      {
        console.log("In delete useeffect")
        DeleteProductComposition({product_id:product_id,composition_id:composition_id})

      }
    
    }, [isDeleteComposition]);
    useEffect(()=>{
      if(deleteSuccessComposition)
        {
          console.log("deleteDataComposition",deleteDataComposition)
          setDeleteModalComposition(false)
          setCompositionId("")
          setIsDeleteComposition(false)
          if(deleteDataComposition.data.error==false)
            {
              toast.success("Composition deleted successfully")
            }
        }
    },[deleteDataComposition])
    const handleFileSelect = (event) => {
        const files = event.target.files;
        const selected = Array.from(files);
        setSelectedImages(selected);

        // Create image previews
        const previews = selected.map(file => URL.createObjectURL(file));
        setImagePreviews(previews);
    };
    const handleImageUpload=async()=>{
        console.log("handle image upload",selectedImages)
        if (selectedImages.length==0) {
            toast.error('Please select an image to upload.');
            return;
          } 
          else
          {
            console.log("selectedImages axios",selectedImages)
            const formData = new FormData();
            for (let i = 0; i < selectedImages.length; i++) {
                const file = selectedImages[i];
                if (file.size <= 25 * 1024 * 1024) {
                    formData.append('product_photos', file);
                } else {
                 return console.log(`Skipping file ${file.name} due to size limit exceeded`);
                }
              }
           // formData.append('product_photos', selectedImages);
            formData.append("product_id",product_id)
            try{
                const response = await axios.post(`${Config.API_URL_LOCAL}product/uploadProductPhoto`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      Authorization: `Bearer ${userToken}`,
                    },
                    onUploadProgress: (progressEvent) => {
                      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                      setUploadProgress(progress);
                    },
                  });
                  console.log("image upload response", response); // Log the response data
                  // Check response status or data to determine success
                  if (response.status === 200) {
                      // Handle success
                      toast.success(response.data.message);
                      setTimeout(()=>{
                        setUploadProgress(0);
                      },2000)
                      refetch()
                      setSelectedImages([])
                      setImagePreviews([])
                  } else {
                      // Handle failure
                      toast.error('Failed to upload image.');
                  }
            }
            catch (error) {
                console.log("image upload error",error)
                toast.error('Error uploading image.');
              }
          } 
    }
    const clearSeletedImages=()=>{
        setSelectedImages([])

        setImagePreviews([])
    }
    const deleteProductImage=(image_id)=>{
      console.log("Image id",image_id)
      setDeleteModal(true)
      setImageId(image_id)

    }
    const deleteProductComposition=(composition_id)=>{
      console.log("Composition id",composition_id)
      setDeleteModalComposition(true)
     // setIsDeleteComposition(true)
      setCompositionId(composition_id)

    }
    useEffect(()=>{
      console.log("Unse effet called",image_id)
      console.log("useeffect",product_id)
      console.log("IsthumbnailImage",is_thumbnail_image)
      if(image_id!==undefined && product_id!==undefined)
      {
        MarkThumbnailImage({image_id:image_id,product_id,is_thumbnail_image})

      }
    },[thumbnailIndex])
    useEffect(()=>{
      if(thumbnailImageSuccess)
      {
        toast.success("Image set as thumbnail image")
      }
    },[thumbnailImageData])
   
    useEffect(()=>{
      if(deleteSuccess)
      {
        toast.success(deleteData.message)
        setDeleteModal(false)
        setIsDelete(false)
        setImageId("")
  
      }
    },[deleteData])
    const toggleThumbnail = (index,image_id) => {
      console.log()
      console.log("Index",index)
      console.log("ImageId",image_id)
      console.log("thumbnailIndex",thumbnailIndex)
      const isChecked = index === thumbnailIndex;
      if (isChecked) {
          setThumbnailIndex(-1); // If already selected, deselect it
      } else {
          setThumbnailIndex(index);
          
      }
      setIsThumbnailImage(!isChecked)
      setImageId(image_id)
    };
    const onSubmit=()=>{
      AddProductComposition({composition_name,composition_quantity,product_id})
    }

  
    const productDetailsScreen=()=>{
        return(
            <>
            <ToastContainer/>
              <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
              <
                WarningModal
                setShowWarningModal={setDeleteModalComposition}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDeleteComposition}
                showModal={isDeleteModalComposition}
              />
             
        <main id="main" class="main">
        {
        isLoadingPage?"Loading...":
        <div>
             <section id="services" class="services">
                <div className='container'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                             <p>
                             Product Name:{product_details.product_name}
                                
                                </p> 
                                <p>
                             Product Introduction:{product_details.product_introduction}                                
                                </p>
                                <p>
                             Product Benifits:{product_details.product_benefits}                                
                                </p>  
                                <p>
                             Packaging:{product_details.packing_details}                                
                                </p> 
                                <p>
                             Crops:{product_details.recommended_crops}                                
                                </p>  
                                <p>
                             Dosage:{product_details.recommended_dose}                                

                                </p>
                                <p>
                             Dripping:{product_details.recommended_dripping}                                

                                </p>
                                <p>
                                Disclaimer:{product_details.disclaimer}                                
                                </p>
                                <p>
                                Caution:{product_details.caution}                                
                                </p>
                                <p>
                             Product on homepage:{product_details.is_homepage?" Yes":" No"}                                

                                </p>
                                <p>
                             Product Link:{product_details.product_link}                                

                                </p>
                            </div>
                        </div>
                    </div>
                      <div className='row'>
                      <div className='col-md-6'>
                        <div className='card'>
                          <div className='card-body'>
                          <h5 className="card-title">Add Product Composition</h5>
                          <div className="form-group">
                  <label htmlFor="input1">Composition Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="composition_name"
                  value={composition_name}
                  onChange={(e) => setCompositionName(e.target.value)}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input1">Composition Quantity</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="composition_quantity"
                  value={composition_quantity}
                  onChange={(e) => setCompositionQuantity(e.target.value)}
                   />
                </div>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {compositionIsLoading ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>
                          </div>
                        </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='card'>
                            <div className='card-body'>
                            <table class="table">
  <thead>
    <tr>
    <th scope="col">Action</th>
      <th scope="col">#</th>
      <th scope="col">Composition</th>
      <th scope="col">Percentage</th>
     
    </tr>
  </thead>
  <tbody>
    {
      productComposition.map((composition_data,index)=>{

       return( <tr>
        <td>
        <i class="fa fa-trash" aria-hidden="true"  onClick={()=>deleteProductComposition(composition_data._id)}></i>
        </td>
          <td>
            {index+1}
          </td>
          <td>
            {composition_data.composition_name}
          </td>
          <td>
            {composition_data.composition_quantity}
          </td>
        </tr>)
      })
    }
    
   
  </tbody>
</table>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                            <input type="file" accept="image/*"
                             multiple onChange={handleFileSelect} />
                              {imagePreviews.map((preview, index) => (
                                                        <img key={index} src={preview} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
                                                    ))}
                                                   
                                                   
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                       
                        <div className='row'>
                        <div className='col-md-3'>
                       
                    <button 
                     className="btn btn-primary" 
                    onClick={handleImageUpload}>Upload</button>
                    </div>
                    <div className='col-md-3'>

                    <button 
                     className="btn btn-warning" 
                    onClick={clearSeletedImages}>Clear Selection</button>
                    </div>
                    
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            {uploadProgress  > 0 && (
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          </div>
         
        </div>
         )}
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'20px'}}>
                          <div className='col-md-12'>
                            <div className='card'>
                              <div className='card-body'>
                              <div className='row'>

                              {product_images?.map((data,index)=>{
                                 return(
                                    <div className='col-md-3'>
                                        <button 
                     className="btn btn-primary" 
                    onClick={()=>deleteProductImage(data._id)}>Delete</button>
                     <input
                        type="checkbox"
                        checked={index === thumbnailIndex}
                        style={{margin:"10px"}}
                        onChange={() => toggleThumbnail(index,data._id)}
                    />Thumbnail image
                                     <img
                
                 src={`${Config.API_URL_IMAGE}/ProductPhotos/${data.image_path}`}

                  alt={`Image ${index}`}
                  style={{ width: '100%', cursor:'pointer',objectFit: 'cover' }}
                  //onClick={() => handleImageClick(image)}
                />
                </div>
                                  
                                 ) 
                              })}
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                   
                    </div>
                </div>
             </section>
        </div>
        }
        </main>
            </>
        )
    }

  return (
    <Template>{productDetailsScreen()}</Template>
  )
}

export default ProductDetails