export default build =>
    build.query({
        tagTypes: ['allTestimonials'],
        keepUnusedDataFor: 1,
        query(payload) {           
            return {
                url: `/testimonial/getAllTestimonials`,            
                method: 'GET'              
            };
           
        },
       providesTags: ['allTestimonials']
    })
